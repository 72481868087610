import { selectRequestData, selectRequestState } from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Page from "../../../components/Page";
import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import Heading from "../../../components/Heading";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import Table from "../../../components/Table";
import { getConversationPrompts } from "../../../store/actions/ai";
import NewButton from "../../../components/NewButton";
import UploadAIPromptTemplatesModal from "./UploadAIPromptTemplatesModal";
import useLoadingState from "../../../utils/use-loading-state";
import { message } from "antd";

const AIPromptTemplates = ({
  loadingState,
  prompts,
  getConversationPrompts
}) => {
  const header = s("aiPromptTemplates.page.header", "AI Prompt Templates");
  const errorText = s(
    "aiPromptTemplates.page.messages.getPromptsFailed",
    "Failed to load prompt templates"
  );

  const button = s(
    "aiPromptTemplates.page.upload.button",
    "Upload AI Prompt Templates"
  );
  const [showModal, setShowModal] = useState(false);

  const data =
    (prompts &&
      prompts.map((prompt) => ({
        ...prompt,
        key: prompt.name
      }))) ||
    [];

  useEffect(() => {
    getConversationPrompts();
  }, []);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      message.error(errorText);
    }
  );

  const onUpload = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: s("aiPromptTemplates.page.table.name", "AI Prompt Template"),
      dataIndex: "name",
      key: "name",
      sorter: false
    }
  ];

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <Container>
          <Heading level={"h3"}>{header}</Heading>
          <ButtonBar>
            <NewButton
              type={"primary"}
              onClick={onUpload}
              data-cy={"upload-prompt-templates"}
            >
              {button}
            </NewButton>
          </ButtonBar>
          <Table
            columns={columns}
            dataSource={data}
            variant={"smallBody"}
            pagination={false}
          />
        </Container>
      </OrganizationPageLayout>
      {showModal ? <UploadAIPromptTemplatesModal onClose={onClose} /> : null}
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_CONVERSATION_PROMPTS_REQUEST
  ),
  prompts: selectRequestData(
    state,
    actionTypes.GET_CONVERSATION_PROMPTS_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getConversationPrompts
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AIPromptTemplates
);
