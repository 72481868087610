import axios from "axios";
import { Auth } from "aws-amplify";
import types from "../store/actionTypes";
import store from "../store/store";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import encodeUriAll from "../utils/encode-uri-all";
import EnvironmentNames from "../utils/constants/EnvironmentNames";

const baseURL = getBaseUrl();

const paramsSerializer = (params) => {
  let result = "";
  Object.keys(params).forEach((key) => {
    if (key === "search" && params[key] !== undefined) {
      result += `${key}=${encodeUriAll(params[key])}&`;
    } else if (params[key] !== undefined) {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    }
  });
  return result.substring(0, result.length - 1);
};

const http = axios.create({ baseURL: `${baseURL}/`, paramsSerializer });

let guestId = null;

const replaceAuthenticationHeader = (request) => {
  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${getToken()}`
  };
  return request;
};

const refreshToken = () => {
  if (store.getState().authentication.refreshCall) {
    return store.getState().authentication.refreshCall;
  }

  const refreshCall = Auth.currentSession()
    .then((data) => {
      store.dispatch({
        type: types.TOKEN_REFRESH_SUCCESS,
        payload: { token: data.getAccessToken().getJwtToken() }
      });
    })
    .catch(() => {
      store.dispatch({
        type: types.TOKEN_REFRESH_FAILURE
      });
      return error;
    });

  store.dispatch({
    type: types.TOKEN_REFRESH_REQUEST,
    payload: {
      refreshCall
    }
  });

  return refreshCall;
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      return refreshToken().then(() => {
        return http.request(replaceAuthenticationHeader(error.config));
      });
    }

    return Promise.reject(error);
  }
);

function getBaseUrl() {
  const matches = window.location.hostname.match(/^.*?([^.]+)\.cuvama\.com$/);

  if (matches) {
    switch (matches[1]) {
      case "dev":
        return "https://api.dev.cuvama.com";
      case "demo":
        return "https://api.demo.cuvama.com";
      default:
        return "https://api.cuvama.com";
    }
  }

  if (window.location.hostname.match(/^.*?([^.]+)\.mac$/)) {
    return "http://api.mac:3001";
  }

  return process.env.REACT_APP_API;
}

function getToken() {
  return store.getState().authentication.token;
}

function getEnvironmentId() {
  const matches = window.location.hostname.match(
    /^(?:([^.]+)\.)?([^.]+)\.cuvama\.com$/
  );

  if (matches) {
    if (matches[2] === "dev") {
      return EnvironmentNames.DEV;
    }

    return EnvironmentNames.LIVE;
  }

  return EnvironmentNames.LOCALHOST;
}

function getTenantId() {
  const matches = window.location.hostname.match(
    /^(?:([^.]+)\.)?([^.]+)\.cuvama\.com$/
  );

  if (window.location.hostname === "engineering.dev.cuvama.com") {
    return "qa";
  }

  if (matches) {
    return matches[1] || matches[2];
  }

  const localhostMatches = window.location.hostname.match(
    /^([^.]+)\.(localhost|local|mac)?$/
  );

  if (localhostMatches) {
    return localhostMatches[1];
  }

  return process.env.REACT_APP_DEFAULT_TENANT_ID;
}

function getGuestId() {
  if (!guestId) {
    guestId = Cookies.get("cuvama_GUEST_ID");
  }
  return guestId;
}

function getRequestHeaders() {
  const headers = {};
  const token = getToken();

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  headers["X-Request-Id"] = uuidv4();
  headers["X-Tenant-Id"] = getTenantId();
  headers["X-Guest-Id"] = getGuestId();

  return headers;
}

function get(url, headers = {}, params = {}) {
  return http.get(url, {
    params,
    headers: { ...getRequestHeaders(), ...headers }
  });
}

function post(url, data, headers = {}, params = {}) {
  return http.post(url, data, {
    params,
    headers: { ...getRequestHeaders(), ...headers }
  });
}

function patch(url, data, headers = {}, params = {}) {
  return http.patch(url, data, {
    params,
    headers: { ...getRequestHeaders(), ...headers }
  });
}

function put(url, data, headers = {}) {
  return http.put(url, data, {
    headers: { ...getRequestHeaders(), ...headers }
  });
}

function doDelete(url, data, headers = {}) {
  return http.delete(url, {
    headers: { ...getRequestHeaders(), ...headers },
    data
  });
}

const uploadFile = (url, file, onUploadProgress, signal) => {
  const config = {
    timeout: 60 * 1000,
    headers: {
      "content-type": file.type
    }
  };

  if (onUploadProgress) {
    config["onUploadProgress"] = onUploadProgress;
  }

  if (signal) {
    config["signal"] = signal;
  }

  return http.put(url, file, config);
};

const methods = {
  http,
  get,
  post,
  patch,
  put,
  delete: doDelete,
  uploadFile,
  getTenantId,
  getEnvironmentId
};
export default methods;
