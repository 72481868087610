import PropTypes from "prop-types";
import { NewButton, Icon } from "..";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Operations } from "./DiscoveryOperations";
import Permissions from "../../utils/permissions";
import { hasPermission } from "../../store/reducers";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import styled from "styled-components";
import { themeProp } from "../../theme";

const AIButton = ({
  discoveryId,
  tooltip,
  isInternalUser,
  startDiscoveryOperation,
  canSeeAIFeature,
  isMandatoryQuestionsCompleted
}) => {
  const onClick = () => {
    startDiscoveryOperation({
      operationId: Operations.AI,
      operationDiscoveryId: discoveryId
    });
  };

  return isInternalUser && canSeeAIFeature ? (
    <StyledButton
      type={"iconDarkPrimary"}
      onClick={onClick}
      tooltip={tooltip}
      data-cy={"ai-button"}
      disabled={!isMandatoryQuestionsCompleted}
    >
      <Icon name={"aiIcon"} size={"medium"} colour={"inherit"} />
    </StyledButton>
  ) : null;
};

const StyledButton = styled(NewButton)`
  svg {
    .path1 {
      fill: ${themeProp(`palette.accent5`)};
    }
    .path2 {
      fill: ${themeProp(`palette.accent4`)};
    }
    .path3 {
      fill: ${themeProp(`palette.accent6`)};
    }
  }
`;

AIButton.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  isMandatoryQuestionsCompleted: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isInternalUser: hasPermission(state, Permissions.SEE_MY_DISCOVERIES),
  canSeeAIFeature: hasPermission(state, Permissions.SEE_AI_EMAIL_TEMPLATES)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(AIButton);
