import React, { useEffect } from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import { TextArea } from "../../Input";
import NewButton from "../../NewButton";
import Form, { FormItem } from "../../Form";
import { useString as s } from "../../../components/StringProvider";
import { useChat } from "./ChatProvider";

const ChatForm = () => {
  const placeholderText = s(
    "aiDiscoveryModal.popup.chatForm.prompt.placeholder",
    "Message Cuvama AI"
  );
  const sendButtonText = s(
    "aiDiscoveryModal.popup.chatForm.send.button",
    "Send"
  );

  const [form] = Form.useForm();
  const {
    canEnterPrompt,
    selectedPrompt,
    setSelectedPrompt,
    prompt,
    setPrompt,
    setIsChatActive,
    scrollToChatBottom
  } = useChat();

  useEffect(() => {
    if (selectedPrompt?.text) {
      form.setFieldValue("prompt", selectedPrompt.text);
      setPrompt(selectedPrompt.text);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    form.setFieldValue("prompt", prompt);
  }, [prompt]);

  const onValuesChange = ({ prompt }) => {
    setPrompt(prompt);

    if (!prompt) {
      setSelectedPrompt(null);
    }
  };

  const sendPrompt = () => {
    scrollToChatBottom();
    setSelectedPrompt(null);
    setIsChatActive(true);
    form.resetFields();

    // Call function that Sends prompt to the AI, then clear the prompt state when the request is in progress
    setPrompt("");
  };

  return (
    <StyledForm
      form={form}
      onValuesChange={onValuesChange}
      disabled={!canEnterPrompt}
    >
      <StyledFormItem name={"prompt"}>
        <TextArea
          placeholder={placeholderText}
          autoSize={{ minRows: 1, maxRows: 5 }}
          data-cy={"prompt-input-field"}
        />
      </StyledFormItem>
      <NewButton
        type={"submit"}
        onClick={sendPrompt}
        disabled={!prompt}
        data-cy={"send-prompt-button"}
      >
        {sendButtonText}
      </NewButton>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  background-color: ${themeProp("palette.gray1")};
  border-radius: 6px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  align-items: flex-end;

  & > * {
    flex: 1;
  }

  & > *:first-child {
    flex: 10;
    display: flex;
    align-self: center;
  }

  button {
    padding: 14px 20px !important;
  }

  textarea {
    max-width: 100%;
    border-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 0px;
    font-family: ${themeProp("typography.body.fontFamily")};

    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }

    &.ant-input.ant-input-disabled {
      width: 100%;
      background-color: transparent;
      border-color: transparent;
    }
  }

  textarea:hover {
    border-color: transparent;
  }

  textarea::placeholder {
    color: ${themeProp("palette.gray4")};
  }
`;

const StyledFormItem = styled(FormItem)`
  display: flex;

  & > * {
    flex: 1;
  }
`;

export default ChatForm;
