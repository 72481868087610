import types from "../actionTypes";
import aiService from "../../services/ai.service";

export const companyResearch =
  ({
    email,
    companyName,
    companyWebsite,
    productName,
    marketSegment,
    top5Competitors
  }) =>
  (dispatch) => {
    dispatch({
      type: types.COMPANY_RESEARCH_REQUEST,
      parameters: {
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      }
    });

    return aiService
      .researchCompany({
        email,
        companyName,
        companyWebsite,
        productName,
        marketSegment,
        top5Competitors
      })
      .then((payload) => {
        dispatch({
          type: types.COMPANY_RESEARCH_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.COMPANY_RESEARCH_FAILURE,
          error
        });
      });
  };

export const getConversationPrompts = () => (dispatch) => {
  dispatch({
    type: types.GET_CONVERSATION_PROMPTS_REQUEST
  });

  return aiService
    .getConversationPrompts()
    .then((payload) => {
      dispatch({
        type: types.GET_CONVERSATION_PROMPTS_SUCCESS,
        payload
      });
    })
    .catch((error) => {
      dispatch({
        type: types.GET_CONVERSATION_PROMPTS_FAILURE,
        error
      });
    });
};
