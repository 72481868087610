import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import Heading from "../../Heading";
import { Paragraph } from "../../Typography";

const ChatGuide = () => {
  const content = {
    title: s("aiDiscoveryModal.popup.chatGuide.title", "How to use Cuvama AI"),
    subTitle: s(
      "aiDiscoveryModal.popup.chatGuide.subTitle",
      "Welcome to the Cuvama AI feature! Here’s a quick guide to what you can and cannot do with this tool:"
    ),
    heading1: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading1.title",
        "What You Can Do"
      ),
      list: [
        s(
          "aiDiscoveryModal.popup.chatGuide.heading1.list.0",
          "Generate Impactful Cold Outbound Messages: Utilize Cuvama's value proposition to craft compelling cold outbound messages."
        ),
        s(
          "aiDiscoveryModal.popup.chatGuide.heading1.list.1",
          "Create CoM Discovery Cheat Sheets: Develop cheat sheets for discovery using Challenges & Outcomes."
        ),
        s(
          "aiDiscoveryModal.popup.chatGuide.heading1.list.2",
          "Generate Discovery Questions Lists: Create lists of discovery questions based on Challenges & Inputs."
        )
      ]
    },
    heading2: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading2.title",
        "Current Limitations"
      ),
      list: [
        s(
          "aiDiscoveryModal.popup.chatGuide.heading2.list.0",
          "Update Discoveries: The AI currently does not support making updates to the discovery information."
        ),
        s(
          "aiDiscoveryModal.popup.chatGuide.heading2.list.1",
          "Ask Usage Questions: Inquiring about using Cuvama features or best practices for value selling is not available at this time. This functionality is on our roadmap."
        )
      ]
    },
    heading3: {
      title: s(
        "aiDiscoveryModal.popup.chatGuide.heading3.title",
        "Your Feedback Matters!"
      ),
      subTitle: s(
        "aiDiscoveryModal.popup.chatGuide.heading3.subTitle",
        "We value your input and are constantly striving to improve. Please click here to provide your feedback and help us enhance Cuvama AI."
      )
    }
  };

  return (
    <ChatGuideContainer data-cy={"chat-guide"}>
      <ChatGuideInner>
        <ChatGuideSection>
          <Heading level={"h3"}>{content.title}</Heading>
          <Paragraph>{content.subTitle}</Paragraph>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading1.title}
          </Paragraph>
          <ChatGuideList>
            {content.heading1.list.map((item, index) => (
              <ChatGuideListItem key={index}>{item}</ChatGuideListItem>
            ))}
          </ChatGuideList>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading2.title}
          </Paragraph>
          <ChatGuideList>
            {content.heading2.list.map((item, index) => (
              <ChatGuideListItem key={index}>{item}</ChatGuideListItem>
            ))}
          </ChatGuideList>
        </ChatGuideSection>
        <ChatGuideSection>
          <Paragraph variant={"bMediumMobile"}>
            {content.heading3.title}
          </Paragraph>
          <Paragraph>{content.heading3.subTitle}</Paragraph>
        </ChatGuideSection>
      </ChatGuideInner>
    </ChatGuideContainer>
  );
};

const ChatGuideContainer = styled.section`
  height: 100%;
  overflow-y: scroll;
`;

const ChatGuideInner = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > :nth-child(2) {
    margin-top: 10px;
  }
`;

const ChatGuideSection = styled.div`
  & > *:first-child {
    margin-bottom: 5px;
  }
`;

const ChatGuideList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ChatGuideListItem = styled.li`
  list-style-type: disc;
  margin-left: 20px;
`;

export default ChatGuide;
