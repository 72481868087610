import {
  isAuthenticated,
  isInitialLoginComplete,
  selectInitializationError,
  selectRequestData,
  selectRequestState,
  selectUserId
} from "../store/reducers";
import actionTypes from "../store/actionTypes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import useLoadingState from "../utils/use-loading-state";
import ErrorPage from "./ErrorPage";
import { updateMyInvitations } from "../store/actions/invitations";
import { getMyUserInformation, logout } from "../store/actions/authentication";
import {
  getUserSettings,
  setUserSettings
} from "../store/actions/user-settings";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import InitializationError from "./InitializationError";
import httpService from "../services/http.service";
import ChannelNames from "../utils/channel-names";
import pusherService from "../services/pusher.service";
import AboutUserModal from "../components/UserInformation/AboutUserModal";

const handleInitialization = ({ initializationError }) => {
  return initializationError ? (
    <Switch>
      <Route path="/initialization-error" component={InitializationError} />
      <Redirect to="/initialization-error" />
    </Switch>
  ) : (
    ""
  );
};

const AuthenticationHandler = ({
  children,
  isAuthenticated,
  userInfoLoadingState,
  updateInvitationsLoadingState,
  updateMyInvitations,
  getMyUserInformation,
  userSettings,
  getUserSettings,
  setUserSettings,
  isInitialLoginComplete,
  initializationError,
  logout,
  userId
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isInitialLoginComplete) {
      getMyUserInformation();

      if (isAuthenticated) {
        setReady(false);
      }
    }
  }, [isInitialLoginComplete, isAuthenticated]);

  /*
    Resubscribe to the auth channel when the User navigates to a
    new page in case "Pusher" was disconnected (due to a long running
    browser session, for example)
   */

  useEffect(() => {
    if (userId) {
      const tenantId = httpService.getTenantId();
      const channelName = ChannelNames.AUTH({ userId, tenantId });
      if (!pusherService.isSubscribed(channelName)) {
        pusherService.subscribe({
          channelName,
          eventName: "logout",
          callback: () => {
            logout();
          }
        });
      }
    }
  }, [location, userId]);

  useLoadingState(userInfoLoadingState, () => {
    if (isAuthenticated) {
      setReady(false);
      getUserSettings();
      updateMyInvitations();
    } else {
      setReady(true);
    }
  });

  useEffect(() => {
    if (isAuthenticated && userSettings && !userSettings.timeZone) {
      setUserSettings({
        ...userSettings,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
  }, [userSettings]);

  useLoadingState(
    updateInvitationsLoadingState,
    () => {
      setReady(true);
    },
    () => {
      setError(true);
    }
  );

  if (error) {
    return <ErrorPage />;
  }

  if (initializationError) {
    return handleInitialization({ initializationError });
  }

  if (!ready || !isInitialLoginComplete) {
    return null;
  }

  return (
    <>
      {children}
      <AboutUserModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  updateInvitationsLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_MY_INVITATIONS_REQUEST
  ),
  userInfoLoadingState: selectRequestState(
    state,
    actionTypes.USER_INFO_REQUEST
  ),
  userSettings: selectRequestData(state, actionTypes.GET_USER_SETTINGS_REQUEST),
  isInitialLoginComplete: isInitialLoginComplete(state),
  initializationError: selectInitializationError(state),
  userId: selectUserId(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMyInvitations,
      getMyUserInformation,
      getUserSettings,
      setUserSettings,
      logout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationHandler);
