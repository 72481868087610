import React, { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Heading,
  Text,
  Alert,
  Icon,
  NewButton,
  Tooltip
} from "../../../../components";
import styled from "styled-components";
import SalesforceInstallationStatus from "./SalesforceInstallationStatus";
import { useString as s } from "../../../../components/StringProvider";
import pusherService from "../../../../services/pusher.service";
import ChannelNames from "../../../../utils/channel-names";
import httpService from "../../../../services/http.service";
import { selectRequestData } from "../../../../store/reducers";
import {
  getSalesforceInstallation,
  startSalesforceInstallation
} from "../../../../store/actions/config";
import types from "../../../../store/actionTypes";
import JobStatuses from "../../../../utils/job-statuses";
import SalesforceInstallationActions from "../../../../utils/constants/salesforce-installation-actions";
import PusherEventNames from "../../../../utils/constants/pusher-event-names";

const SalesforceInstallation = ({
  salesforceConnection,
  salesforceInstallationData,
  getSalesforceInstallation,
  startSalesforceInstallation
}) => {
  const salesforceInstallationHeader = s(
    "salesforce.installation.header",
    "Salesforce Installation"
  );
  const SalesforceInstallationDescription = s(
    "salesforce.installation.description",
    "Monitor the installation process of the Salesforce managed package directly from Cuvama."
  );
  const installationFailed = s(
    "salesforce.installation.failed",
    "Something went wrong during the installation. Please verify your Salesforce configuration and try again."
  );
  const startInstallationButton = s(
    "salesforce.installation.start.button",
    "Start Installation"
  );
  const startInstallationButtonTooltip = s(
    "salesforce.installation.start.button.tooltip",
    "Connect to Salesforce before starting the installation."
  );
  const restartInstallationButton = s(
    "salesforce.installation.restart.button",
    "Restart Installation"
  );
  const restartInstallationButtonTooltip = s(
    "salesforce.installation.start.button.tooltip",
    "Connect to Salesforce before restarting the installation."
  );

  const { status, message } = salesforceInstallationData || {};

  const messages = {};

  messages[SalesforceInstallationActions.CONFIGURE_CORS] = s(
    "salesforce.installation.action.configureCors",
    "Set up CORS to enable cross-origin requests from specified origins"
  );
  messages[SalesforceInstallationActions.CREATE_AUTH_PROVIDER] = s(
    "salesforce.installation.action.createAuthProvider",
    "Configure an Auth. Provider for OAuth or similar authentication."
  );
  messages[SalesforceInstallationActions.CREATE_NAMED_CREDENTIAL] = s(
    "salesforce.installation.action.createNamedCredential",
    "Create a Named Credential to securely store external system credentials."
  );
  messages[SalesforceInstallationActions.CREATE_REMOTE_SITE] = s(
    "salesforce.installation.action.createRemoteSite",
    "Create a Remote Site Setting to allow external communication."
  );

  useEffect(() => {
    getSalesforceInstallation();
  }, []);

  const tenantId = httpService.getTenantId();

  const onUpdateSalesforceInstallation = () => {
    getSalesforceInstallation();
  };

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.SALESFORCE_INSTALLATION({ tenantId }),
      eventName: PusherEventNames.UPDATE,
      callback: onUpdateSalesforceInstallation
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.SALESFORCE_INSTALLATION({ tenantId }),
        eventName: PusherEventNames.UPDATE,
        callback: onUpdateSalesforceInstallation
      });
    };
  }, []);

  return (
    <Container>
      <Heading level={"h4"}>{salesforceInstallationHeader}</Heading>
      <SalesforceInstallationStatus status={status} />
      <ContainerInner>
        <Text>{SalesforceInstallationDescription}</Text>
        {!!message && (
          <Alert
            data-cy={"salesforce-installation-action"}
            message={messages[message]}
            type={"info"}
            icon={<Icon size={"larger"} name={"alert"} />}
            showIcon
          />
        )}
        {JobStatuses.FAILED === status && (
          <Alert
            data-cy={"salesforce-installation-failed"}
            message={installationFailed}
            type={"error"}
            icon={<Icon size={"larger"} name={"alert"} />}
            showIcon
          />
        )}
        {!status ||
        status === JobStatuses.FAILED ||
        status === JobStatuses.PENDING ? (
          <>
            {JobStatuses.FAILED === status ? (
              <Tooltip
                title={
                  !salesforceConnection ? restartInstallationButtonTooltip : ""
                }
              >
                <NewButton
                  onClick={startSalesforceInstallation}
                  data-cy={"restart-installation-button"}
                  disabled={!salesforceConnection}
                >
                  {restartInstallationButton}
                </NewButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  !salesforceConnection ? startInstallationButtonTooltip : ""
                }
              >
                <NewButton
                  onClick={startSalesforceInstallation}
                  data-cy={"start-installation-button"}
                  disabled={!salesforceConnection}
                >
                  {startInstallationButton}
                </NewButton>
              </Tooltip>
            )}
          </>
        ) : null}
      </ContainerInner>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

SalesforceInstallation.propTypes = {
  salesforceConnection: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  salesforceInstallationData: selectRequestData(
    state,
    types.GET_SALESFORCE_INSTALLATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSalesforceInstallation,
      startSalesforceInstallation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SalesforceInstallation
);
