import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Heading from "../../Heading";
import { ReactComponent as AIIcon } from "../../../assets/AIIcon.svg";
import { useString as s } from "../../../components/StringProvider";
import ChatMenu from "./ChatMenu";
import Chat from "./Chat";
import ChatGuide from "./ChatGuide";
import { Icon, NewButton } from "../../../components";

const ChatLayout = ({ onClose }) => {
  const [isGuideOpen, setIsGuideOpen] = useState(false);

  const title = s("aiDiscoveryModal.title", "Cuvama AI");
  const backButton = s("aiDiscoveryModal.back.button", "Back");
  const infoButton = s("aiDiscoveryModal.info.button", "How to use Cuvama AI");

  return (
    <ChatLayoutContainer>
      <ChatMenuLayout>
        <ChatHeading>
          <AIIcon />
          <Heading level="h3">{title}</Heading>
        </ChatHeading>
        <ChatMenu />
      </ChatMenuLayout>
      <MainChatLayout>
        <ChatNavigation>
          <ChatNavigationInner marginTop>
            {isGuideOpen && (
              <NewButton
                type={"tertiary"}
                onClick={() => setIsGuideOpen(false)}
                data-cy={"back-button"}
              >
                <Icon name={"arrowLeft"} />
                {backButton}
              </NewButton>
            )}
          </ChatNavigationInner>
          <ChatNavigationInner>
            {!isGuideOpen && (
              <NewButton
                type={"iconTextSecondary"}
                onClick={() => setIsGuideOpen(true)}
                data-cy={"info-button"}
              >
                <Icon name={"article"} size={"large"} />
                {infoButton}
              </NewButton>
            )}
            <NewButton type={"closeIcon"} onClick={onClose}>
              <Icon
                name={"close"}
                colour={"gray4"}
                size={"large"}
                data-cy={"close-button"}
              />
            </NewButton>
          </ChatNavigationInner>
        </ChatNavigation>
        <ChatContainer>
          {!isGuideOpen && <Chat />}
          {isGuideOpen && <ChatGuide />}
        </ChatContainer>
      </MainChatLayout>
    </ChatLayoutContainer>
  );
};

ChatLayout.propTypes = {
  onClose: PropTypes.func.isRequired
};

const ChatLayoutContainer = styled.section`
  height: 680px;
  display: flex;

  & > * {
    flex: 1;
  }
`;

const ChatMenuLayout = styled.section`
  background-color: ${themeProp("palette.gray5")};
  max-width: 280px;
  padding: 32px;
  border-radius: 6px 0 0 6px;
  display: flex;
  flex-direction: column;
`;

const MainChatLayout = styled.section`
  background-color: ${themeProp("palette.white")};
  padding: 16px 32px 32px;
  border-radius: 0 6px 6px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > * {
    flex: 1;
  }
`;

const ChatHeading = styled.div`
  display: flex;
  align-items: center;
  line-height: 0px;
  gap: 8px;

  h3 {
    margin-bottom: 0;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ChatNavigation = styled.section`
  display: flex;
  justify-content: space-between;
  max-height: 52px;
`;

const ChatNavigationInner = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  height: 40px;
  margin-top: ${({ marginTop }) => (marginTop ? "12px" : "")};
`;

const ChatContainer = styled.section``;

export default ChatLayout;
