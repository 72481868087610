import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NumberInput from "./NumberInput";
import { useMobileMediaQuery } from "../Responsive";
import Slider from "../Slider";

const SliderInput = ({
  color,
  value,
  step,
  marks,
  min,
  max,
  onChange,
  onAfterChange,
  isInputLimited,
  formatter,
  tipFormatter,
  inputFormatter,
  inputParser,
  disabled,
  resetButton,
  ...props
}) => {
  const inputRef = useRef();
  const isMobile = useMobileMediaQuery();

  return (
    <Container className={isMobile ? "mobile" : undefined}>
      <SliderWrapper data-cy={props.code ? `slider-${props.code}` : ""}>
        <Slider
          value={value}
          style={{ width: isMobile ? undefined : 450 }}
          step={step}
          marks={marks}
          min={min}
          max={max}
          onChange={onChange}
          onAfterChange={onAfterChange}
          tipFormatter={tipFormatter}
          included={true}
          disabled={disabled}
          color={color}
        />
      </SliderWrapper>
      <InputWrapper
        className={isMobile ? "mobile" : undefined}
        data-cy={props.code ? `slider-input-${props.code}` : ""}
      >
        <NumberInput
          size="large"
          value={value}
          min={isInputLimited ? min : undefined}
          max={isInputLimited ? max : undefined}
          formatter={inputFormatter}
          parser={inputParser}
          onChange={onChange}
          onCommit={onAfterChange}
          disabled={disabled}
          ref={inputRef}
          {...props}
        />
        {resetButton}
      </InputWrapper>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;

  &.mobile {
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }
`;
const SliderWrapper = styled.div``;
const InputWrapper = styled.div`
  margin-left: 16px;

  &.mobile {
    margin-left: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.mobile > *:not(:first-child) {
    margin-left: 16px;
  }
`;

SliderInput.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.object,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  formatter: PropTypes.func,
  tipFormatter: PropTypes.func,
  inputFormatter: PropTypes.func,
  inputParser: PropTypes.func,
  isInputLimited: PropTypes.bool,
  disabled: PropTypes.bool,
  resetButton: PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"])
};
SliderInput.defaultProps = {
  step: 1,
  isInputLimited: true,
  color: "secondary"
};

export default SliderInput;
