import React from "react";
import styled from "styled-components";
import Icon from "../../Icon";
import { useString as s } from "../../../components/StringProvider";
import { Paragraph } from "../../Typography";
import { themeProp } from "../../../theme";
import { mockPrompts } from "./mock";
import { useChat } from "./ChatProvider";

const ChatPrompt = () => {
  const promptHeading = s(
    "aiDiscoveryModal.chat.prompt.heading",
    "Select Data Sources, choose or create a prompt, and you're ready to hit Send"
  );
  const { selectedPrompt, setSelectedPrompt } = useChat();

  return (
    <ChatPromptContainer data-cy={"chat-prompt"}>
      <ChatPromptHeader>
        <Icon name={"aiPrompt"} colour={"primary"} size={"xlarge"} />
        <Paragraph variant={"bMedium"}>{promptHeading}</Paragraph>
      </ChatPromptHeader>
      <ChatPromptLine />
      <ChatPromptButtonContainer>
        {mockPrompts.map((item, index) => (
          <ChatPromptButton
            key={index}
            $active={selectedPrompt?.name === item.name}
            onClick={() => setSelectedPrompt(item)}
          >
            <Icon name={"work"} colour={"primary"} size={"medium"} />
            <Paragraph variant={"body"}>{item.name}</Paragraph>
          </ChatPromptButton>
        ))}
      </ChatPromptButtonContainer>
    </ChatPromptContainer>
  );
};

const ChatPromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatPromptHeader = styled.div`
  max-width: 472px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
`;

const ChatPromptLine = styled.hr`
  width: 40px;
  margin: 24px 0px;
  border: 1px solid ${themeProp("palette.gray2")};
`;

const ChatPromptButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ChatPromptButton = styled.button`
  display: flex;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 6px;
  border-style: none;
  border: 1px solid ${themeProp("palette.gray2")};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
    0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  background-color: ${({ $active }) =>
    $active ? themeProp("palette.secondary") : themeProp("palette.surface")};
  color: ${({ $active }) =>
    $active ? themeProp("palette.surface") : themeProp("palette.text")};
  font-weight: ${({ $active }) =>
    $active
      ? themeProp("typography.h5Small.fontWeight")
      : themeProp("typography.body.fontWeight")};
  font-family: ${themeProp("typography.body.fontFamily")};
  cursor: pointer;
  text-align: left;

  .ant-typography {
    color: ${({ $active }) =>
      $active ? themeProp("palette.surface") : themeProp("palette.text")};
  }

  svg {
    color: ${({ $active }) =>
      $active ? themeProp("palette.surface") : themeProp("palette.primary")};
    font-weight: ${({ $active }) =>
      $active
        ? themeProp("typography.h5Small.fontWeight")
        : themeProp("typography.body.fontWeight")};
    margin-right: 12px;
    margin-top: 3px;
  }

  &:hover {
    .ant-typography {
      color: ${({ $active }) =>
        $active
          ? themeProp("palette.surface")
          : themeProp("palette.secondary")};
    }

    svg {
      color: ${({ $active }) =>
        $active
          ? themeProp("palette.surface")
          : themeProp("palette.secondary")};
    }
  }
`;

export default ChatPrompt;
