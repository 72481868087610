import React from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import PropTypes from "prop-types";
import { Paragraph } from "../../Typography";
import { themeProp } from "../../../theme";
import WiggleLoader from "../../Loader/WiggleLoader";
import AIChatLoadingNames from "../../../utils/constants/ai-chat-loading-names";

const ChatLoader = ({ type }) => {
  const loader = {
    [AIChatLoadingNames.GENERATING]: {
      title: s(
        "aiDiscoveryModal.chat.loader.generating.title",
        "Processing your request, which may take 20-30 seconds"
      ),
      subTitle: s(
        "aiDiscoveryModal.chat.loader.generating.subTitle",
        "AI can make mistakes. Always review AI-created content"
      )
    },
    [AIChatLoadingNames.REGENERATING]: {
      title: s(
        "aiDiscoveryModal.chat.loader.regenerating.title",
        "Regenerating the response, which may take 20-30 seconds"
      ),
      subTitle: s(
        "aiDiscoveryModal.chat.loader.regenerating.subTitle",
        "AI can make mistakes. Always review AI-created content"
      )
    }
  };

  const content = loader[type];

  return (
    <ChatLoaderContainer role="alert" aria-live="assertive">
      <ChatLoaderImage>
        <WiggleLoader />
      </ChatLoaderImage>
      <ChatLoaderContent>
        <Paragraph variant={"body"} color={"text"}>
          {content.title}
        </Paragraph>
        <Paragraph variant={"small"} color={"gray4"}>
          {content.subTitle}
        </Paragraph>
      </ChatLoaderContent>
    </ChatLoaderContainer>
  );
};

ChatLoader.propTypes = {
  type: PropTypes.oneOf([
    AIChatLoadingNames.GENERATING,
    AIChatLoadingNames.REGENERATING
  ])
};

const ChatLoaderContainer = styled.div`
  background-color: ${themeProp("palette.surface")};
  border: 1px solid ${themeProp("palette.gray2")};
  padding: 16px;
  border-radius: 6px;
  width: fit-content;
  display: flex;
  gap: 12px;
`;

const ChatLoaderImage = styled.div``;
const ChatLoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default ChatLoader;
