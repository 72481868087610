import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Markdown from "../../Discovery/Markdown";
import formatMarkdownText from "../../../utils/format-markdown-text";
import { themeProp } from "../../../theme";
import { mockChat } from "./mock";
import AiChatRoleNames from "../../../utils/constants/ai-chat-role-names";
import ChatLoader from "./ChatLoader";
import { useChat } from "./ChatProvider";
import ChatError from "./ChatError";
import ChatControlPanel from "./ChatControlPanel";

const ChatMessage = () => {
  const { chatEndRef, scrollToChatBottom } = useChat();

  const [lastAssistantIndex, setLastAssistantIndex] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState({
    active: false,
    type: ""
  });

  useEffect(() => {
    if (mockChat) {
      setLastAssistantIndex(
        mockChat.findLastIndex(
          (chat) => chat.role === AiChatRoleNames.ASSISTANT
        )
      );
    }

    scrollToChatBottom();
  }, [mockChat]);

  return (
    <ChatMessageContainer>
      {mockChat.map((item, index) => (
        <Message
          key={item.id}
          data-message-id={item.id}
          data-message-author-role={item.role}
          role={item.role}
        >
          <MessageInner role={item.role}>
            <StyledMarkdown paragraphMargin={true}>
              {formatMarkdownText(item.content)}
            </StyledMarkdown>
            <ChatControlPanel
              role={item.role}
              content={item.content}
              isRegenerateActive={index === lastAssistantIndex}
            />
          </MessageInner>
        </Message>
      ))}
      {loading.active && <ChatLoader type={loading.type} />}
      {error && <ChatError />}
      <ChatScrollBottom ref={chatEndRef} />
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Message = styled.div`
  width: 100%;
  display: flex;

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      justify-content: flex-end;
    `}
`;

const MessageInner = styled.article`
  padding: 16px;
  border-radius: 6px;

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      background-color: ${themeProp("palette.accent3")};
      max-width: 525px;
    `}

  ${({ role }) =>
    role === AiChatRoleNames.ASSISTANT &&
    css`
      background-color: ${themeProp("palette.surface")};
      border: 1px solid ${themeProp("palette.gray2")};
    `}
`;

const ChatScrollBottom = styled.div`
  margin-top: -20px;
`;

const StyledMarkdown = styled(Markdown)`
  * {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: ${themeProp(`typography.bLarge.fontSize`)};
    line-height: ${themeProp(`typography.bLarge.lineHeight`)};
    font-weight: ${themeProp(`typography.bLarge.fontWeight`)};
    font-family: ${themeProp(`typography.bLarge.fontFamily`)};
  }

  h2 {
    font-size: ${themeProp(`typography.bLargeMobile.fontSize`)};
    line-height: ${themeProp(`typography.bLargeMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bLargeMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bLargeMobile.fontFamily`)};
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${themeProp(`typography.bMedium.fontSize`)};
    line-height: ${themeProp(`typography.bMedium.lineHeight`)};
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 12px 0px;
  }

  p {
    font-size: ${themeProp(`typography.body.fontSize`)};
    line-height: ${themeProp(`typography.body.lineHeight`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    font-family: ${themeProp(`typography.body.fontFamily`)};
  }

  hr {
    margin: 12px 0px;
    border: 0;
    border-top: 1px solid ${themeProp("palette.gray2")};
  }

  ul,
  ol,
  li {
    padding-bottom: 8px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-left: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 12px 0px;
  }

  th,
  td {
    border-bottom: 1px solid ${themeProp("palette.gray2")};
    padding: 16px;
    text-align: left;
    font-size: ${themeProp(`typography.bodyMobile.fontSize`)};
    line-height: ${themeProp(`typography.bodyMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bodyMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bodyMobile.fontFamily`)};
  }

  th {
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
    color: ${themeProp("palette.gray4")};
    border: none;

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  thead {
    background-color: ${themeProp("palette.gray1")};
    border: none;
  }
`;

export default ChatMessage;
