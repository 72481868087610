import React, { createContext, useContext, useState, useRef } from "react";
import PropTypes from "prop-types";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isChatActive, setIsChatActive] = useState(false);
  const [canEnterPrompt, setCanEnterPrompt] = useState(true);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompt, setPrompt] = useState("");

  const chatEndRef = useRef(null);

  const scrollToChatBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const startNewChat = () => {
    scrollToChatBottom();
    setIsChatActive(false);
    setCanEnterPrompt(true);
    setSelectedPrompt(null);
    setPrompt("");

    // Call function that starts a new chat
  };

  return (
    <ChatContext.Provider
      value={{
        isChatActive,
        setIsChatActive,
        startNewChat,
        canEnterPrompt,
        setCanEnterPrompt,
        selectedPrompt,
        setSelectedPrompt,
        prompt,
        setPrompt,
        chatEndRef,
        scrollToChatBottom
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useChat = () => {
  const context = useContext(ChatContext);

  return context;
};

export default ChatProvider;
